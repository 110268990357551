import React, { Component } from 'react';
import cookie from 'react-cookies';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
var jwtDecode = require('jwt-decode');


class CustomFunctions extends Component {

  jsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return jsonString;
    }
  }

  validateEmail = (email) => {
    var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(email);
  }

  validatePhone = (phone) => {
    var phoneformat = /^\d{7,15}$/; // eslint-disable-line
    return phone.match(phoneformat);
  }

  validateNumber = (phone) => {
    var phoneformat = /^\d{1,15}$/; // eslint-disable-line
    return phone.match(phoneformat);
  }

  validateAmount = (amount) => {
    var amountFormat = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    return amountFormat.test(amount)
  }

  loadScript = async (src) => {
    return new Promise(function (resolve, reject) {
      const ele = document.getElementById(src);
      if (ele) {
        ele.remove();
      }
      var script = document.createElement("script");
      script.id = src;
      script.src = src;
      script.type = "text/javascript";
      script.async = false;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
    // script.type = "text/babel";
    // script.type = "text/jsx";
    //  document.getElementsByClassName("wrapper")[0].appendChild(script);
  }

  loadStylesheet = async (src, preload = false) => {
    return new Promise(function (resolve, reject) {
      const ele = document.getElementById(src);
      if (ele) {
        ele.remove();
      }
      var script = document.createElement("link");
      script.id = src;
      script.href = src;
      script.type = "text/css";
      if (preload) {
        script.rel = 'preload';
        script.as = 'style';
      } else {
        script.rel = 'stylesheet';
      }
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.head.appendChild(script);
    });
    // script.type = "text/babel";
    // script.type = "text/jsx";
    //  document.getElementsByClassName("wrapper")[0].appendChild(script);
  }

  removeLandingAssets = async () => {
    var baseurl = window.location.origin;
    var elements = [
      baseurl + "/assets/landingassets/css/bootstrap.min.css",
      baseurl + "/assets/landingassets/css/owl.carousel.min.css",
      baseurl + "/assets/landingassets/css/flaticon.css",
      baseurl + "/assets/landingassets/css/slicknav.css",
      baseurl + "/assets/landingassets/css/animate.min.css",
      baseurl + "/assets/landingassets/css/magnific-popup.css",
      baseurl + "/assets/landingassets/css/fontawesome-all.min.css",
      baseurl + "/assets/landingassets/css/themify-icons.css",
      baseurl + "/assets/landingassets/css/slick.css",
      baseurl + "/assets/landingassets/css/nice-select.css",
      baseurl + "/assets/landingassets/css/style.css",
      'landing-bootsrap',
      'landing-style'
    ];
    elements.map((src) => {
      let ele = document.getElementById(src);
      if (ele) {
        ele.remove();
      }
    })
  }

  getUserData = async () => {
    try {
      // const userdata = await cookie.load('frontuserdata');
      const userdata = await localStorage.getItem('frontuserdata');
      const decodedData = jwtDecode(userdata);
      return decodedData;
    } catch (err) {
      return null;
    }
  }

  dataURItoBlob = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  validatePhoneNumber(phoneNumber) {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
    // if(valid) {
    //   this.setState({
    //     message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
    //     color:'green'
    //   });
    // } else {
    //   this.setState({
    //     message:'Phone number '+phoneNumber+' is not valid',
    //     color:'red'
    //   });
    // }
  }

  getValidNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)
  }

  getMonths = () => {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  }
}

export default new CustomFunctions();
