import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import i18n from 'i18next';
import './i18n';
import Routes from './routes';
import firebase from './init-firebase';
import Notification from './util/Notification';

import customFunctions from './util/customFunctions';
import AuthApi from './util/authApi';
import Api from './util/api';

let locationInterval;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: '',
      options: {},
      userData: {}
    };
  }

  componentWillReceiveProps = async (props) => {
    const userData = await customFunctions.getUserData();
    if (userData !== this.state.userData) {
      if (userData && userData.data && userData.roleid === 3) {
        await this.setUserData();
        await this.setupNotification();
      }
    }
  }

  componentWillMount = async () => {
    setTimeout(async () => {
      var lang = await sessionStorage.getItem('gc_lang') || 'en';
      i18n.changeLanguage(lang);
      sessionStorage.setItem('language', lang);
      if (lang === 'ar') {
        document.body.dir = 'rtl';
        document.body.classList = 'rtl-view';
      }
      this.forceUpdate();
    }, 200);
    this.getDriverCurrentLocation();
  }

  getDriverCurrentLocation = async () => {
    const userData = await customFunctions.getUserData();
    if (userData && userData.data && userData.data.roleid === 3) {
      var $this = this;
      $this.getLocation(userData);
      locationInterval = setInterval(() => {
        $this.getLocation(userData);
      }, 15000);
    }
  }

  getLocation = async (userData) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const driverData = await customFunctions.getUserData();
        if (driverData && driverData.data && driverData.data.roleid === 3)
          this.sendDriverLocation(currentPosition, userData.data.driverid);
      });
    }

  }

  sendDriverLocation = async (position, driverId) => {
    const payload = {
      driverid: driverId,
      location: `${position.lat},${position.lng}`
    };
    await AuthApi.postDataToServer(Api.setDriverLocation, payload);
  }

  componentWillUnmount = () => {
    if (locationInterval) {
      clearInterval(locationInterval);
    }
  }

  componentDidMount = async () => {
    const userData = await customFunctions.getUserData();
    if (userData && userData.data && userData.data.roleid === 3) {
      await this.setUserData();
      await this.setupNotification();
    }
  }

  setupNotification = async () => {
    const messaging = firebase.messaging();
    messaging.requestPermission().then(() => {
      return messaging.getToken();
    }).then(async (token) => {
      localStorage.setItem('notification-token', token);
      await this.setUserToken(token);
      //messaging.usePublicVapidKey(token);
    }).catch(async (err) => {
      localStorage.setItem('notification-token', '');
      await this.setUserToken('');
      console.log('error', err);
    })

    messaging.onTokenRefresh(() => {
      messaging.getToken().then(async (refreshedToken) => {
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // Send Instance ID token to app server.
        localStorage.setItem('notification-token', refreshedToken);
        await this.setUserToken(refreshedToken);
        //messaging.usePublicVapidKey(refreshedToken);
        // ...
      }).catch(async (err) => {
        // console.log('Unable to retrieve refreshed token ', err);
        localStorage.setItem('notification-token', '');
        await this.setUserToken();
        console.log('error', err);
      });
    });

    messaging.onMessage(async (payload) => {
      //console.log('Message received. ', payload);
      const icon = window.location.origin + '/logo192.png';
      const { id } = this.state.userData;
      if (!id || id == undefined) return;

      const conversation = payload.data;
      const notification = payload.notification;
      const title = (notification !== undefined) ? notification.title : conversation.title;
      const body = (notification !== undefined) ? notification.body : conversation.body;
      const options = {
        tag: Date.now(),
        body: body,
        icon: icon,
        //lang: customFunctions.getCurrentLanguage(),
        //dir: customFunctions.getCurrentLanguage() === 'en' ? 'ltr' : 'rtl',
        data: payload
      }
      this.setState({
        title: title,
        options: options
      });
    });
  }

  setUserData = async () => {
    const userData = await customFunctions.getUserData();
    this.setState({ userData: ((userData && userData.data) ? userData.data : {}) });
  }

  setUserToken = async (token = '') => {
    const { id } = this.state.userData;
    if (!id || id == undefined) return;

    const postData = {
      deviceid: '',
      devicetype: window.navigator.userAgent,
      token: token,
      userid: parseInt(id)
    };
    const callback = AuthApi.postDataToServer;
    const endPoint = Api.setfirebasetoken;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      //success
    } else {
      // failed
    }
  }

  handlePermissionGranted = () => {
    this.setState({
      ignore: false
    });
  }

  handlePermissionDenied = () => {
    this.setState({
      ignore: true
    });
  }

  handleNotSupported = () => {
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick = (e, tag) => {
    window.focus();
    var data = e.target.data;
    if (data && data.data) {
      if (data.data.type === "DRVNEWSHIPALK") {
        var shippingid = JSON.parse(data.data.maincontent).shippingid !== undefined ? JSON.parse(data.data.maincontent).shippingid : '';
        var url = shippingid ? '/pickup-shipment/' + shippingid : '/shipping-consignments';
        this.props.history.push(url);
      }
    }
  }

  handleNotificationOnError = (e, tag) => {
  }

  handleNotificationOnClose = (e, tag) => {
  }

  handleNotificationOnShow = (e, tag) => {

  }

  render() {
    const { title, ignore, options } = this.state;
    const childProps = {};
    return (
      <React.Fragment>
        <Notification
          ignore={ignore && title !== ''}
          notSupported={this.handleNotSupported}
          onPermissionGranted={this.handlePermissionGranted}
          onPermissionDenied={this.handlePermissionDenied}
          onShow={this.handleNotificationOnShow}
          onClick={this.handleNotificationOnClick}
          onClose={this.handleNotificationOnClose}
          onError={this.handleNotificationOnError}
          timeout={5000}
          title={title}
          options={options}
        />
        <Routes childProps={childProps} />
      </React.Fragment>
    );
  }
}

export default App;