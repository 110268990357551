import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { } from "react-i18next";
// import translationEn from './util/i18n/translations/en.json';
// import translationAr from './util/i18n/translations/ar.json';

// const resources = {
//   en: {
//     translation: translationEn,
//   },
//   ar: {
//     translation: translationAr,
//   },
// };
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    preload: ['en', 'ar'],
    // resources,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/translations/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
    }
  })

export default i18n