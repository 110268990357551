import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from './components/async.component';

const AsyncLogin = asyncComponent(() => import('./screens/Login'));
const AsyncRegister = asyncComponent(() => import('./screens/Register'));
const AsyncHome = asyncComponent(() => import('./screens/Home'));
const AsyncOrder = asyncComponent(() => import('./screens/Orders'));
const AsyncAddOrder = asyncComponent(() => import('./screens/Orders/createOrder'));
const AsyncTrackOrder = asyncComponent(() => import('./screens/Orders/trackOrder'));
const AsyncDriverOrders = asyncComponent(() => import('./screens/Drivers/orders'));
const AsyncTrackShipment = asyncComponent(() => import('./screens/Drivers/trackOrder'));
const AsyncDeliverShipment = asyncComponent(() => import('./screens/Drivers/deliverShipment'));
const AsyncOrderPayment = asyncComponent(() => import('./screens/Orders/orderPayment'));
const AsyncAddresses = asyncComponent(() => import('./screens/Addresses/addreslist'));
const AsyncAddAddresses = asyncComponent(() => import('./screens/Addresses/addaddress'));
const AsyncPaymentSuccess = asyncComponent(() => import('./screens/Orders/paymentSuccess'));
const AsyncOtpScreen = asyncComponent(() => import('./screens/Otp'));
const AsyncProfile = asyncComponent(() => import('./screens/Profile'));
const AsyncDriverLogin = asyncComponent(() => import('./screens/Drivers/login'));
const AsyncDriverHome = asyncComponent(() => import('./screens/Drivers/home'));
const AsyncDriverConsignments = asyncComponent(() => import('./screens/Drivers/shippingConsignment'));
const AsyncPickupShipment = asyncComponent(() => import('./screens/Drivers/pickupShipment'));
const AsyncDriverMap = asyncComponent(() => import('./screens/Drivers/map'));
const AsyncForgetPassword = asyncComponent(() => import('./screens/ForgetPassword'));
const AsyncVendorOrderPayment = asyncComponent(() => import('./screens/Orders/vendorOrderPayment'));
const AsyncLandingPage = asyncComponent(() => import('./screens/LandingPages/landing'));
const AsyncAboutPage = asyncComponent(() => import('./screens/LandingPages/about'));
const AsyncTermsPage = asyncComponent(() => import('./screens/LandingPages/terms'));
const AsyncPrivacyPage = asyncComponent(() => import('./screens/LandingPages/privacy'));
const AsyncFaqPage = asyncComponent(() => import('./screens/LandingPages/faq'));
const AsyncBankDetails = asyncComponent(() => import('./screens/BankDetails'));
const AsyncVendorEarnings = asyncComponent(() => import('./screens/Earnings'));
const AsyncMerchantFaqPage = asyncComponent(() => import('./screens/LandingPages/merchant-faq'));
const AsyncTrackUserOrder = asyncComponent(() => import('./screens/Orders/trackSingleOrder'));
const AsyncDriverBooking = asyncComponent(() => import('./screens/Drivers/bookings'));
const AsyncMerchants = asyncComponent(() => import('./screens/Merchants'));
const AsyncAddMerchant = asyncComponent(() => import('./screens/Merchants/addMerchants'));
const AsyncChangePassword = asyncComponent(() => import('./screens/Profile/changePassword'));

const AppRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Component {...props} />
    )}
  />
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps }) => {

  return (
    <Switch>
      <AppRoute path="/login" exact component={AsyncLogin} props={childProps} />
      <AppRoute path="/signup" exact component={AsyncRegister} props={childProps} />
      <AppRoute path="/orders" exact component={AsyncOrder} props={childProps} />
      <AppRoute path="/add-order" exact component={AsyncAddOrder} props={childProps} />
      <AppRoute path="/order-payment/:shipmentId" exact component={AsyncVendorOrderPayment} props={childProps} />
      <AppRoute path="/track-order" exact component={AsyncTrackOrder} props={childProps} />
      <AppRoute path="/driver-orders" exact component={AsyncDriverOrders} props={childProps} />
      <AppRoute path="/track-shipment/:orderId" exact component={AsyncTrackShipment} props={childProps} />
      <AppRoute path="/deliver-shipment/:orderId" exact component={AsyncDeliverShipment} props={childProps} />
      <AppRoute path="/addresses" exact component={AsyncAddresses} props={childProps} />
      <AppRoute path="/addresses/add" exact component={AsyncAddAddresses} props={childProps} />
      <AppRoute path="/addresses/edit/:addressId" exact component={AsyncAddAddresses} props={childProps} />
      <AppRoute path="/payment-success" exact component={AsyncPaymentSuccess} props={childProps} />
      <AppRoute path="/verify-otp" exact component={AsyncOtpScreen} props={childProps} />
      <AppRoute path="/home" exact component={AsyncHome} props={childProps} />
      <AppRoute path="/profile" exact component={AsyncProfile} props={childProps} />
      <AppRoute path="/driver-login" exact component={AsyncDriverLogin} props={childProps} />
      <AppRoute path="/driver-home" exact component={AsyncDriverHome} props={childProps} />
      <AppRoute path="/shipping-consignments" exact component={AsyncDriverConsignments} props={childProps} />
      <AppRoute path="/pickup-shipment/:locationId" exact component={AsyncPickupShipment} props={childProps} />
      <AppRoute path="/map" exact component={AsyncDriverMap} props={childProps} />
      <AppRoute path="/forget-password" exact component={AsyncForgetPassword} props={childProps} />
      <AppRoute path="/about-us" exact component={AsyncAboutPage} props={childProps} />
      <AppRoute path="/terms-and-conditions" exact component={AsyncTermsPage} props={childProps} />
      <AppRoute path="/privacy-policy" exact component={AsyncPrivacyPage} props={childProps} />
      <AppRoute path="/faq" exact component={AsyncFaqPage} props={childProps} />
      <AppRoute path="/merchant-faq" exact component={AsyncMerchantFaqPage} props={childProps} />
      <AppRoute path="/bank-details" exact component={AsyncBankDetails} props={childProps} />
      <AppRoute path="/vendor-earnings" exact component={AsyncVendorEarnings} props={childProps} />
      <AppRoute path="/track-user-order" exact component={AsyncTrackUserOrder} props={childProps} />
      <AppRoute path="/track-user-order/:shipmentId" exact component={AsyncTrackUserOrder} props={childProps} />
      <AppRoute path="/bookings" exact component={AsyncDriverBooking} props={childProps} />
      <AppRoute path="/merchants" exact component={AsyncMerchants} props={childProps} />
      <AppRoute path="/add-merchant" exact component={AsyncAddMerchant} props={childProps} />
      <AppRoute path="/edit-user/:userId" exact component={AsyncAddMerchant} props={childProps} />
      <AppRoute path="/change-password" exact component={AsyncChangePassword} props={childProps} />
      <AppRoute path="/" exact component={AsyncLandingPage} props={childProps} />
    </Switch>);
};
